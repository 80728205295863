<template>
   <div class="modal" :class="{'is-active': current_job_open}">
         <div class="modal-background" @click.self="$parent.$parent.current_job_open = false"></div>
        <div class="modal-card">
            <div :class="{'confirmed-job': current_job.JobState.ID == 1}" class="modal-card-head">
                <div class="modal-card-title">
                     <p class="is-size-6 has-text-centered is-title has-text-weight-bold" >
                        {{current_job.Caption}}
                        <span v-if="current_job.JobState.ID == 1" class="is-pulled-right is-size-4 confirmed-job-icon" title="Job confirmed">
                            <i class="fas fa-check-square fa-x3"></i>
                        </span>
                    </p>
                </div>
            </div>
            <div class="modal-card-body">
                <!-- Job details head -->
                <div class="is-flex is-justify-content-space-between">
                    <div class="is-align-self-center">
                        <p class="is-size-6">
                            <span class="">
                                {{$t('dates')}} 
                            </span>
                            <span class="has-text-weight-semibold">
                                {{current_job.DayTimeOut | customdate}} - {{current_job.DayTimeIn | customdate}}
                            </span>
                        </p>
                        <p class="is-size-6">
                            <span class=" is-capitalized">
                                {{$t('warehouse')}} 
                            </span>
                            <span class="has-text-weight-semibold">
                                {{current_job.Stock.Caption}}
                            </span>
                        </p>
                    </div>
                    <div>
                        <p class="is-size-7 has-text-right">
                            <span class="has-text-weight-semibold">
                                {{current_job.IdJob}}
                            </span>
                            <br>
                            {{$t('number')}} 
                            <span class="has-text-weight-semibold">
                                {{current_job.Number}}
                            </span>
                        </p>
                    </div>
                </div>
                <div v-if="current_job.JobState.ID != 1 && am_allowed('warehouse.can_confirm_job')" class="job-not-confirmed p-2 my-3 is-flex is-align-items-center">
                    <!-- Info about the job or go to next step -->
                    <span class="has-text-dark ml-2">
                        <i class="fas fa-exclamation-triangle mx-1"></i>
                        This job is not confirmed yet.
                    </span>
					<a @click.prevent="saveStatus" class="is-pulled-right button is-success ml-auto" style="" >
						Confirm job
					</a>
                    <!-- <p class="is-size-6">
                        <span class="is-capitalized">
                            {{$t('status')}} 
                        </span>
                        <span :class="{'has-text-success is-pulled-right': current_job.JobState.ID == 1}"
                            class="has-text-weight-semibold">
                            {{current_job.JobState.Caption}}
                        </span>
                    </p> -->
                </div>
                <!-- Job materials list -->
                <table class="table is-fullwidth  mt-1 mb-3 has-background-white has-text-dark">
                    <thead>
                        <tr class="" style="font-size:12px;">
                            <th class="is-capitalized has-text-dark">
                                {{$t('material')}} 
                            </th>
                            <th class="has-text-right is-capitalized has-text-dark">
                                {{$t('quantity')}} 
                            </th>
                            <th class="has-text-right has-text-dark">
                                #
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-for="material in job_materials" :key="material.IdST"> -->
                        <template v-for="( material, index ) in job_materials">
                            <tr :key="index">
                                <td class="">
                                    <div class="is-flex">
                                        <span v-if="material.notes && material.notes.length > 0" class="is-align-self-center">
                                            <a @click.prevent="show_material_notes(material)" href="" title="Material Notes" class="button is-small  mr-2 ml-0 p-2">
                                                <i class="fas fa-align-left mt-1"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <span style="font-size:15ssspx;">
                                                {{material.Caption}}
                                            </span>
                                            <span class="has-text-grey-light">x{{material.Qty}}</span>
                                        </span>
                                    </div>
                                </td>
                                <th class="has-text-right">
                                    <div v-if="!material.edit_mode">
                                        {{material.Qty}}
                                    </div>
                                    <div  v-else>
                                        <div v-if="am_allowed('warehouse.can_edit')" class="field has-addons mb-1 add-materials-table-input ">
                                            <p class="control is-fullwidth" style="width: 100%;">
                                                <input v-model="material.corrected_qty"
                                                    :max="material.Qty"
                                                    :placeholder="material.Qty" 
                                                    :class="{'is-danger' : material.Qty < material.corrected_qty}"
                                                    type="number" class="input is-small add-materials-table-input is-fullwidth job-details-qty-input">
                                            </p>
                                        </div>
                                        <div v-else>
                                            {{material.Qty}}
                                        </div>
                                    </div> 
                                </th>
                                <th class="has-text-right" width="150px" style="vertical-align:niddle;">
                                    <div class="" >
                                        <span v-if="material.edit_mode">
                                            <a @click.prevent="cancel_edit_material(material)" class="button is-small"><i class="fas fa-times"></i></a>
                                        </span>
                                        <span v-else>
                                            <a @click.prevent="start_edit_material(material, index)" href="" class="button is-small">
                                                <i class="fas fa-cog"></i>
                                            </a>
                                        </span>

                                    </div>
                                    
                                    <!-- <a v-else @click.prevent="start_edit_material(material, index)" class="button is-small" href="">
                                        <i class="fas fa-pencil-alt fa-xs"></i>
                                    </a> -->
                                </th>
                            </tr>
                            <tr :key="'M' + index" v-if="material.edit_mode">
                                    <td colspan="2">
                                    <select class="thin-select" v-model="material.note_type">
                                        <option value="0">Comunication</option>
                                        <option value="1">Material/s amount change</option>
                                        <option value="2">Material/s delete</option>
                                        <option value="3">Material/s exchange</option>
                                    </select>
                                    <textarea placeholder="Leave a comment..." 
                                        v-model="material.note" 
                                        style="max-width:100%" 
                                        class="input has-border"></textarea>
                                </td>
                                <td class="has-text-right">
                                    <span v-if="material.note && material.note != ''">
                                        <a @click.prevent="save_material(material)" class="button is-small mr-1"><i class="fas fa-check"></i></a>
                                        <a v-if="am_allowed('warehouse.can_delete')" @click.prevent="delete_warehouse_material(material)" href="" class="button is-small">
                                            <i class="far fa-trash-alt"></i>
                                        </a>
                                    </span>
                                    <span v-else>
                                        <a disabled class="button is-small mr-1"><i class="fas has-text-success fa-check"></i></a>
                                        <a v-if="am_allowed('warehouse.can_delete')" disabled class="button is-small"><i class="far fa-trash-alt has-text-danger"></i></a>
                                    </span>
                                </td>
                            </tr>
                            <tr :key="'N' + index"  class="ml-3">
                                <td colspan=3 class="p-0">
                                    <table class="table is-fullwidth is-narrow" style="min-width:100%;">
                                        <template td v-if="material.show_notes" class="ml-3">
                                            <tr v-for="(note, indexa) in material.notes" :key="indexa">
                                                <td class="p-1 pl-2">
                                                    <div class="has-border-bottom-grey">
                                                        <p class="">
                                                            <small class="has-text-grey-light ">
                                                                {{note.note_type}}
                                                            </small>
                                                            <small class="has-text-grey-light is-pulled-right" style="font-size:11px;">
                                                                {{note.created_by_name}} - {{note.created_at | customdate}} 
                                                            </small>
                                                        </p>
                                                        <p>
                                                            {{note.content}}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <!-- Info, Next step, close,  -->
                
                <!-- Warehouse job notes -->
                <div class="has-text-dark" style="background: rgb(231 247 183);">
                    <p class="is-size- px-2 py-1">
                        <span @click="show_warehouse_job_notes = !show_warehouse_job_notes" class="is-clickable">
                            <span v-if="warehouse_jobs_comments.length > 0">
                                <i v-if="show_warehouse_job_notes" class="fa fa-chevron-up"></i>
                                <i v-else class="fa fa-chevron-down"></i>
                            </span>
                            <span class="ml-1" style="font-size:10px;">
								<small v-if="warehouse_jobs_comments.length > 0">
                                    {{warehouse_jobs_comments.length}}
                                </small>
                                <span class="is-uppercase" >
                                    Job related discussion
                                </span>
                            </span>
                        </span>
                        <a @click.prevent="start_create_warehouse_job_note()" href="" class="is-pulled-right">
                            <i v-if="show_create_note" class="fa fa-times"></i>
                            <i v-else class="fa fa-plus"></i>
                        </a>
                    </p>
                    <div v-if="show_create_note ">
                        <div class="is-flex">
                            <div class="column is-10">
                                <div>
                                    <select class="thin-select" v-model="new_note.note_type">
                                        <option value="0">Comunication</option>
                                        <option value="1">Material/s amount change</option>
                                        <option value="2">Material/s delete</option>
                                        <option value="3">Material/s exchange</option>
                                    </select>
                                </div>
                                <textarea v-model="new_note.content" rows=3 class="textarea is-small has-border" placeholder="Create new note"></textarea>
                            </div>
                            <div class="column is-flex" style="align-items: flex-end">
                                <a @click.prevent="create_note()" href="" class="button is-success-dark is-small is-fullwidth">Send</a>
                            </div>
                        </div>
                    </div>
                    <div v-if="show_warehouse_job_notes" class="px-2">
                        <div v-for="(note, index) in warehouse_jobs_comments" :key="index" class="has-border-bottom-grey">
                            <p class="is-flex ">
                                <small class="has-text-grey-light" style="font-size:11px;">
                                    {{note.note_type}} <br>
                                    {{note.material_name ? note.material_name : ''}} 
                                </small>
                                <small class="has-text-grey-light ml-auto" style="font-size:11px;">
                                    {{note.created_by_name}} - {{note.created_at | customdate}} 
                                </small>
                            </p>
                            <p>
                                {{note.content}}
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <button class="modal-close is-large" 
            @click.prevent="$parent.$parent.current_job_open = false" aria-label="close"></button>
    </div>
</template>
<script>
import axios from 'axios'
import permissionMixin from '@/permissions-mixin.js'
    export default{
        mixins: [ permissionMixin ],
        props: ['job_materials', 'current_job_open', 'current_job'],
        data(){
            return {
                // corrected_qty: 0
                warehouse_job_notes: [],
                show_create_note: false,
                new_note: { note_type: 0},
                show_warehouse_job_notes: true,
      
            }
        },
        methods:{
            start_edit_material(m){
                this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.map(mat => {
                    if(mat == m){
                        // mat.Qty = mat.corrected_qty
                        mat.edit_mode = !mat.edit_mode
                        // console.log('m, mat, index', m, mat, index)
                    }
                    return mat
                })
                // this.job_materials.map(mat => {
                //     if(mat == m) mat.edit_mode = !mat.edit_mode
                // })
                // m.edit_mode = !m.edit_mode
                // this.corrected_qty = m.Qty
                // console.log('m',m)
            },


            save_material(m){
				var is_edited = false
				console.log(is_edited, m.corrected_qty, m.Qty)

                if(m.corrected_qty){
                    if(m.corrected_qty > m.Qty){
                        this.$fire({
                            text: this.$t('qty_not_higher', {qty: m.Qty}), // 'The quantity can not be higher than ' + m.Qty,
                            type: 'warning',
                            showCloseButton: true,
                            closeButtonText: 'Close ...',
                            width: 300,
                            customClass: {
                                confirmButton: 'button is-success is-small',
                                cancelButton: 'button is-small',
                            },
                        })
                    } else {
						if(m.corrected_qty != m.Qty){
							is_edited = true
                            this.$fire({
                                text: this.$t("are_you_sure"),
                                showCancelButton: true,
                                confirmButtonText: this.$t("yes"),
                                cancelButtonText: this.$t("no"),
                                width: 300,
                                customClass: {
                                    confirmButton: 'button is-success is-small',
                                    cancelButton: 'button is-small',
                                },
                            }).then(response => {
                                if(response.value){

                                    var post_data = {
                                        IdJob: this.current_job.ID,
                                        IdStockType: m.IdST,
                                        IdStockType2JobGroup: m.IdST2JG,
                                        IdStockType2JobType: m.IdST2JT,
                                        Quantity: m.corrected_qty - m.Qty,
                                    }
                                    axios.post(this.$ej_server + '/api.json/Items/Book', post_data, { headers: this.$ej_headers })
                                    .then(r => {
                                        // notification r.data.Message.
                                        this.$toast(r.data.Message)

                                        axios.post(this.$tetris_server + '/edit/job/material', {
                                            material: m, 
                                            job: this.current_job,
                                        })
                                        .then(res => {
                                            if(res.data[0] == 'error'){
                                                this.$toast(res.data[1])
                                            } else {
                                                this.create_project_jobs_comments(m ,is_edited).then(() => {
                                                    // notification m.Caption
                                                    this.$toast(this.$t("material_amount_updated_to",{mat:m.Caption, qty:m.corrected_qty}))
                                                    
                                                    // update and add to job_materials list
                                                    this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.map(mat => {
                                                        if(mat == m){
                                                            mat.Qty = mat.corrected_qty
                                                            mat.edit_mode = false
                                                            mat.show_notes = false
                                                        }
                                                        return mat
                                                    })
                                                    // Reload!!
                                                    // To do: update data in warehouse
                                                    window.location.reload()
                                                })
                                            }
                                        })
                                    })
                                }
                            })
						}
                    }
                } else {
                    this.create_project_jobs_comments(m ,is_edited)
                }
            },
            async create_project_jobs_comments(m, is_edited){
                // Create project_jobs_comments
                m.note_type = (is_edited ? -1 : m.note_type) 
                axios.post(this.$tetris_server + '/create/project/jobs/comments', {
                    material: m,
                    user: JSON.parse(localStorage.user),
                    ej_id: this.current_job.IdJob,
                    project_id: this.$parent.$parent.currentEvent
                }) .then(res => {
                    this.$parent.$parent.currentJobItems = [...this.$parent.$parent.currentJobItems.map(mat => {
                        if(m == mat){
                            console.log(res.data)
                            res.data[0].note_type = 
                                res.data[0].note_type == 0 ? 'just-note' :
                                res.data[0].note_type == 1 ? 'material-amount-change-request' : 
                                res.data[0].note_type == 2 ? 'material-delete-request' : 
                                res.data[0].note_type == 3 ? 'material-exchange-request' : 


                            m.notes.unshift(res.data[0])
                        }
                        // console.log('this.$parent.$parent.currentJobItems', this.$parent.$parent.currentJobItems)
                        return mat
                    })]
                    m.note = ''
                    m.edit_mode = false
                    this.$toast(this.$t("note_created_successfully",), 'success')
                    return 0
                })

            },
            delete_warehouse_material(m){
                this.$fire({
                        text: this.$t("are_you_sure"),
                        showCancelButton: true,
                        confirmButtonText: this.$t("yes"),
                        cancelButtonText: this.$t("no"),
                        width: 300,
                        customClass: {
                            confirmButton: 'button is-success is-small',
                            cancelButton: 'button is-small',
                        },
                    }).then(response => {
                        if(response.value){
                            var post_data = {
                                IdJob: this.current_job.ID,
                                IdStockType: m.IdST,
                                IdStockType2JobGroup: m.IdST2JG,
                                IdStockType2JobType: m.IdST2JT,
                                // Quantity: m.corrected_qty - m.Qty,
                                Quantity: 0 - m.Qty,
                            }
                            axios.post(this.$ej_server + '/api.json/Items/Book', post_data, { headers: this.$ej_headers })
                            .then(() => {
                                // notification r.data.Message.
                                // this.$toast(r.data.Message)
                                this.$toast(this.$t("material_removed_from_job",{mat:m.Caption, qty:m.corrected_qty}))
            
                                axios.post(this.$tetris_server + '/delete/job/material', {
                                    material: m, 
                                    job: this.current_job,
                                })
                                .then(() => {
                                    // Remove dhe deleted material
                                    this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.filter(mat => mat != m )
                                    
                                    // Delete Job if it have no materials
                                    if(this.$parent.$parent.currentJobItems == 0){
                                        // console.log('Delete this JOB!!!', this.current_job.IdJob)
                                        axios.post(this.$tetris_server + '/delete/warehouse/jobs', {warehouse_job: this.current_job.IdJob})
                                        .then(() => {
                                            axios.post(this.$ej_server + '/api.json/jobs/delete/' + this.current_job.IdJob, {}, { headers: this.$ej_headers })
                                            .then(() => {
                                                

												window.location.reload()
                                            })
                                        })
                                    } else {
                                        this.$toast(this.$t("material_removed_from_job",{mat:m.Caption, qty:m.corrected_qty}))
                                        // Create project_jobs_comments

                                        // Reload!!
                                        // To do: update data in warehouse
                                        window.location.reload()
                                    }
                                })
                            })


                            // Create project_jobs_comments
                            axios.post(this.$tetris_server + '/create/project/jobs/comments', {
                                material: {...m, note_type: -2},
                                user: JSON.parse(localStorage.user),
                                ej_id: this.current_job.IdJob,
                                project_id: this.$parent.$parent.currentEvent
                            })


                        }
                    })
            },
			saveStatus(){
				this.$parent.$parent.postEJDatas('Jobs/Save/', {
					Address_Delivery: this.current_job.Address_Delivery, 
					Caption: this.current_job.Caption,
					Contact_Delivery: this.current_job.Contact_Delivery,
					DayTimeIn: this.current_job.DayTimeIn,
					DayTimeOut: this.current_job.DayTimeOut,
					IdJob: this.current_job.IdJob,
					JobState: {IdJobState: 1, ID: 1},
					Project: this.current_job.Project,
					Stock: this.current_job.Stock
				}).then(() => {
					// console.log(resp.data)
					// send to other sockets this change 
					// client.emit('tellOthers', dataToEmit, function(error, message){
					 	window.location.reload()
					// });


				})
			},
            cancel_edit_material(m){
                // console.log('m...', m)

                this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.map(mat => {
                    if(mat == m){
                        // mat.Qty = mat.corrected_qty
                        mat.edit_mode = false
                        mat.corrected_qty = null
                    }
                    return mat
                })

                if(m.note){
                    m.note = ''
                }
            },
            get_materials_notes(){

                axios.post(this.$tetris_server + '/get/materials/notes', { ej_id: this.current_job.IdJob })
                .then(res => {
                    this.warehouse_job_notes = [...res.data]
                    // console.log(this.$parent.$parent.currentJobItems, this.warehouse_job_notes)
                    this.$parent.$parent.currentJobItems = [...this.$parent.$parent.currentJobItems.map(mat => {
                        mat.notes = []
                        mat.note_type = 0
                        this.warehouse_job_notes = [...this.warehouse_job_notes.map(note => {
                            if(mat.IdST == note.material_id){
                                mat.notes.push(note)
                                note.is_assigned = true
                            }
                            return note
                        })]
                        return mat
                    })]
                })
            },
            show_material_notes(m){

                this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.map(mat => {
                    if(mat == m){
                        mat.show_notes = !mat.show_notes
                    }
                    return mat
                })
            },
            start_create_warehouse_job_note(){
                this.show_create_note = !this.show_create_note
            },
            create_note(){
                axios.post(this.$tetris_server + '/create/warehouse/job/note', {
                    note: this.new_note,
                    ej_id: this.current_job.IdJob,
                    user: JSON.parse(localStorage.user),
                    project_id: this.$parent.$parent.currentEvent
                }).then(() => {
                
                    // this.warehouse_job_notes.unshift(res.data[0])
                    this.get_materials_notes()
                    this.new_note = {}
                    this.show_create_note = false

                })
                
            }
        },
        computed: {
            warehouse_jobs_comments(){
                return this.warehouse_job_notes.filter(n => !n.is_assigned);
            }
        },
        
        created(){
            // console.log('current_job', this.current_job)
            this.$parent.$parent.currentJobItems = this.$parent.$parent.currentJobItems.map(mat => {
                mat.edit_mode = false
                mat.show_notes = false
                return mat
            })
            this.get_materials_notes()
        }
    }
</script>

<style>
    .job-details-qty-input::placeholder{
        color:#dedede!important;
    }
    .confirmed-job{
        background:#528365;
    }
    .confirmed-job-icon{
        color: #d0ecb6;
    }
    .job-not-confirmed{
        background:#eaeccb!important;
    }
</style>
